<template>
    <div class="resident-subscriptions-current">

        <DeleteProductPopin
            v-model="deleteProductPopinVisible"
            :product="productToDelete"
            @cancel="onDeleteProductPopinCancel"
            @validate="onDeleteProductPopinValidate"
        />
        <ConfirmExitPopin
            v-model="confirmExitPopinVisible"
            @no="callbacks.no"
            @yes="callbacks.yes"
        />

        <div
            v-if="!loaded"
            class="loading"
        >
          <div class="effect-1 effects"></div>
          <div class="effect-2 effects"></div>
          <div class="effect-3 effects"></div>
        </div>

        <div
            v-else
            class="content"
        >
            <ResidentHeader page-title="Abonnements" />

            <HSecondaryNav
                class="secondary-nav"
                :menuItems="internalMenuItems"
            />

            <HCard
                v-if="!isCurrentSubscriptionValid"
                class="no-subscriptions"
            >
                <div class="empty-title">Aucun abonnement en cours.</div>
                <div class="empty-content">
                    <div class="empty-text">
                        Parcourez le catalogue afin de composer la
                        liste des besoins et des envies du résident.
                    </div>
                    <HBtn
                        v-if="!isEditionForbidden"
                        class="go-catalog-btn"
                        @click="goToCatalog()"
                    >
                        Parcourir le catalogue
                    </HBtn>
                </div>
            </HCard>

            <div
              v-else
              class="subscriptions"
            >
                <GrandTotal
                    class="grand-total"
                    :total="subscriptionAmount"
                />

                <div class="products">
                    <SubscriptionCard
                        v-for="(product, productIndex) in products"
                        :key="productIndex"
                        class="product"
                        :product="product"
                        @update-frequency="onUpdateFrequency"
                        @remove-quantity="onRemoveQuantity"
                        @add-quantity="onAddQuantity"
                        @delete-quantity="onDeleteQuantity"
                        :addQuantityDisabled="product.type == 'service'"
                    >
                        <template v-slot:footer-view>
                            <HBtn
                                style-type="tertiary"
                                icon="PencilIcon"
                                class="edit-btn"
                                data-cy="edit-btn"
                                :disabled="isEditionForbidden"
                                @click="onProductUpdateBtnClick(product)"
                            >Modifier</HBtn>
                        </template>
                        <template v-slot:footer-edit>
                            <HBtn
                                style-type="tertiary"
                                class="cancel-btn"
                                data-cy="cancel-btn"
                                @click="onProductCancelBtnClick(product)"
                            >Annuler</HBtn>
                            <HBtn
                                class="save-btn"
                                data-cy="save-btn"
                                @click="onProductSaveBtnClick(product)"
                            >Enregistrer</HBtn>
                        </template>
                    </SubscriptionCard>
                </div>

                <div class="date-last-update">{{ getSubscriptionDateLegend() }}</div>
            </div>

        </div>

    </div>
</template>

<script>
import async from 'async'
import moment from 'moment'
import _ from 'lodash'

import Formats from '@/constants/formats'
import Routes from '@/constants/routes'

import {
  getNextMonth,
  formatLocalDate
} from '@/modules/date'
import Oauth from '@/oauth'
import {
  getFrequencyLabel
} from '@/modules/labels'
import {
  getDescriptionAsHtml,
  getProductOptionsAsHtml,
  getServiceOptionsAsHtml
} from '@/modules/services'

import GrandTotal from '@/components/GrandTotal'
import FrequencySelector from '@/components/selectors/FrequencySelector'
import ResidentHeader from '@/views/residents/components/ResidentHeader'
import DeleteProductPopin from '@/views/residents/subscriptions/components/DeleteProductPopin'
import ConfirmExitPopin from '@/views/residents/subscriptions/components/ConfirmExitPopin'
import SubscriptionCard from '@/views/residents/subscriptions/components/SubscriptionCard'
import {
  HSecondaryNav,
  HCard,
  HBtn
} from '@happytal/bo-ui-library'

export default {
  props: [
    'resident_id'
  ],
  components: {
    ResidentHeader,
    HSecondaryNav,
    HCard,
    HBtn,
    GrandTotal,
    FrequencySelector,
    SubscriptionCard,
    DeleteProductPopin,
    ConfirmExitPopin
  },
  data () {
    return {
      internalMenuItems: [
        {
          title: 'Récapitulatif',
          icon: 'ClipboardListIcon',
          route: {
            name: Routes.ResidentSubscription
          }
        },
        {
          title: `Prochaine commande - livrée en ${getNextMonth()}`,
          icon: 'PackageIcon',
          route: {
            name: Routes.ResidentSubscriptionsNext
          }
        }
      ],
      deleteProductPopinVisible: false,
      confirmExitPopinVisible: false,
      callbacks: {
        no: () => {},
        yes: () => {}
      },
      pcp: [],
      loaded: false,
      editing: false,
      products: [],
      productSaved: null,
      productToDelete: null
    }
  },
  computed: {
    isEditionForbidden () {
      return this.$acl.not.check('isTutor') &&
      this.$acl.not.check('isFamily') &&
      this.$acl.not.check('isHappytalSupport')
      && this.$acl.not.check('isHappytalEmployee')
    },
    subscriptionAmount () {
      return _.sumBy(this.products, (product) => {
        return product.price * product.quantity
      }).toFixed(2)
    },
    isCurrentSubscriptionValid() {
      if (this.products.length <= 0) {
        return false
      }
      const pcp = this.getCurrentSubscription()
      if (!pcp) {
        return false
      }
      return pcp.shoppingListProducts.length > 0 || pcp.shoppingListServices.length > 0
    }
  },
  methods: {
    getSubscriptionDateLegend() {
      const pcp = this.getCurrentSubscription()
      if (!pcp) {
        return ''
      }
      const dateLegend = formatLocalDate(pcp.updatedOnUtc, Formats.PcpDate)
      return `Date de dernière modification : ${dateLegend}`
    },
    getUserId() {
      return this.$store.getters['dataUser/getUserId']()
    },
    getInstitutionId() {
      return this.$store.getters['dataUser/getInstitutionId']()
    },
    getResidentSubscription(residentId) {
      return this.$store.getters['dataPcps/getResidentPcp'](residentId)
    },
    getCurrentSubscription() {
      return this.getResidentSubscription(this.resident_id)
    },
    getResidentById(residentId) {
      return this.$store.getters['dataResidents/getResidentById'](residentId)
    },
    onUpdateFrequency (product) {
      this.updateProduct(product)
    },
    onRemoveQuantity (product) {
      product.quantity--
      this.updateProduct(product)
    },
    onAddQuantity (product) {
      product.quantity++
      this.updateProduct(product)
    },
    deleteProduct (product) {
      const index = _.findIndex(this.products, (p) => {
        return p.id == product.id
      })
      this.products.splice(index, 1)
    },
    onDeleteQuantity (product) {
      this.productToDelete = product
      this.deleteProductPopinVisible = true
    },
    onDeleteProductPopinCancel () {

    },
    onDeleteProductPopinValidate () {
      this.editing = false
      this.deleteProduct(this.productToDelete)
      this.updateSubscription()
    },
    updateProduct (product) {
      const index = _.findIndex(this.products, (p) => {
        return p.id == product.id
      })
      this.$set(this.products, index, product)
    },
    onProductUpdateBtnClick (product) {
      if (this.editing) {
          this.updateProduct(this.productSaved)
      }
      else {
          this.editing = true
      }
      this.productSaved = product
      this.updateProduct({
        ...product,
        mode: 'edit'
      })
    },
    onProductCancelBtnClick (product) {
      this.editing = false
      this.updateProduct(this.productSaved)
      this.productSaved = null
    },
    onProductSaveBtnClick (product) {
      this.editing = false
      this.productSaved = null
      this.updateProduct({
        ...product,
        mode: 'view'
      })
      this.updateSubscription()
    },
    updateProducts () {
      const pcp = this.getCurrentSubscription()
      if (!pcp) {
        return []
      }
      const products = pcp.shoppingListProducts.filter(product => product.meta.validated)
      .map((product) => {
          const offer = _.get(product, 'meta', null)
          const options = offer ? getProductOptionsAsHtml({
              ...offer,
              variantInfo: {
                  data: pcp.variantData
              }
          }) : null
          const shortDescription = _.get(product, 'meta.product.data.shortDescription', '')
          const description = getDescriptionAsHtml(shortDescription, options)
          return {
              id: product.id,
              type: 'product',
              title: _.get(product, 'meta.product.data.title', ''),
              description,
              imageUrl: _.get(product, 'meta.product.data.mainImage.source', ''),
              price: _.get(product, 'meta.offer.price', 0),
              quantity: _.get(product, 'quantity', 0),
              shopName: _.get(product, 'meta.offer.shopName', 0),
              frequencyInMonth: product.frequencyInMonth,
              mode: 'view'
          }
      })
      const services = pcp.shoppingListServices.filter(product => product.meta.validated)
      .map((product) => {
          product.meta.options = product.options
          const options = getServiceOptionsAsHtml(product)
          const shortDescription = _.get(product, 'meta.service.description', '')
          const description = getDescriptionAsHtml(shortDescription, options)
          return {
              id: product.id,
              type: 'service',
              title: _.get(product, 'meta.serviceModel.title', ''),
              description,
              imageUrl: _.get(product, 'meta.serviceModel.publicData.imageUrl', ''),
              price: _.get(product, 'meta.price', 0),
              quantity: _.get(product, 'quantity', 0),
              shopName: _.get(product, 'meta.service.shop.name', 0),
              frequencyInMonth: product.frequencyInMonth,
              mode: 'view'
          }
      })
      this.products = _.concat(products, services)
    },
    updateSubscription () {
      let products = _.filter(this.products, (product) => {
          return product.type == 'product'
      })
      products = _.map(products, (product) => {
        const subscriptionProduct = _.find(this.pcp.shoppingListProducts, (shoppingListProduct) => {
            return shoppingListProduct.id == product.id
        })
        let result = _.omit(subscriptionProduct, ['meta'])
        result.quantity = product.quantity
        result.frequencyInMonth = product.frequencyInMonth
        return result
      })
      let services = _.filter(this.products, (product) => {
          return product.type == 'service'
      })
      services = _.map(services, (service) => {
        const shoppingListService = _.find(this.pcp.shoppingListServices, (shoppingListService) => {
            return shoppingListService.id == service.id
        })
        let result = _.omit(shoppingListService, ['meta'])
        result.quantity = service.quantity
        result.frequencyInMonth = service.frequencyInMonth
        return result
      })
      this.$store.dispatch('dataOrders/getCurrentOrders', {
        userId: this.getUserId(),
        institutionId: this.getInstitutionId()
      })
      this.$store.dispatch('dataBaskets/getUserBaskets', {
        userId: this.getUserId(),
        institutionId: this.getInstitutionId()
      })
      this.$store.dispatch('dataPcps/updatePcp', {
        pcpId: this.pcp.id,
        products,
        services
      })
    },
    goToCatalog () {
      if (this.editing) {
        return
      }
      this.$router.push({
        name: Routes.ResidentCatalog,
        params: {
          resident_id: this.resident_id
        }
      })
    },
    showSubscriptionErrors(pcp) {
      const products = _.get(pcp, 'errors.products', [])
      products.forEach((product) => {
        this.showErrorToast(`Le produit (offerId:${product.offerId}) n'est plus disponible dans le catalogue.`)
      })
      const services = _.get(pcp, 'errors.services', [])
      services.forEach((service) => {
        this.showErrorToast(`Le service (serviceCode:${service.serviceCode}) n'est plus disponible dans le catalogue.`)
      })
    },
    showErrorToast(text) {
      this.$toasted.show(text, {
        theme: 'hx-toasted',
        position: 'hx-bottom-left',
        duration: null,
        action: [
          {
            text: 'Fermer',
            onClick: (e, to) => {
              to.goAway(0)
            }
          }
        ]
      })
    }
  },
  async mounted() {
    this.loaded = false
    async.series([
          (callback) => {
            if (!this.$store.state.dataResidents.isRegistered) {
              this.$store.dispatch('dataResidents/getResidents', {
                userId: this.getUserId(),
                institutionId: this.getInstitutionId()
              })
              .then(() => {
                callback(null)
              })
              return
            }
            callback(null)
          }
        ],
        (err, results) => {
          Oauth.checkUserRight(this.getResidentById(this.resident_id), this.$router, this.$vs.loading)
          this.$store.dispatch('dataPcps/getResidentPcp', {
              residentId: this.resident_id
          })
          .then((pcpApi) => {
              this.pcp = pcpApi
              this.updateProducts()
              this.showSubscriptionErrors(pcpApi)
              this.loaded = true
          })
          .catch((error) => {
              console.error(error)
              this.pcpMode = 'create'
              this.loaded = true
          })
        }
    )
  },
  beforeRouteLeave (to, from, next) {
    if (!this.editing) {
      next()
      return
    }
    this.callbacks.no = () => {
      next(false)
    }
    this.callbacks.yes = () => {
      this.$store.dispatch('dataPcps/getResidentPcp', {
        residentId: this.resident_id
      })
      .then((pcpApi) => {
        next()
      })
    }
    this.confirmExitPopinVisible = true
  }
}
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';

.resident-subscriptions-current {

    .content {

        .secondary-nav {
            margin: 10px 0px 0px 0px;
        }

        .no-subscriptions {
            margin: 20px 0px 0px 0px;

            .empty-title {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 12px;
                color: #000000;
            }
            .empty-content {
                display: flex;
                align-items: center;
                margin: 10px 0px 0px 0px;

                @media (max-width: map-get($breakpoints, sm)) {
                    flex-wrap: wrap;
                }

                .empty-text {
                    flex-grow: 1;
                }
                .go-catalog-btn {

                    @media (max-width: map-get($breakpoints, sm)) {
                        width: 100%;
                        margin: 20px 0px 0px 0px;
                    }
                }
            }
        }

        .subscriptions {
            margin: 20px 0px 0px 0px;
        }

        .products {
            display: grid;
            grid-template-columns: repeat(auto-fill, 48%);
            grid-auto-rows: auto;
            grid-gap: 22px;
            row-gap: 22px;
            margin: 20px 0px 20px 0px;

            @media (max-width: map-get($breakpoints, sm)) {
                grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
            }

            .product {

                .edit-btn {

                }
                .cancel-btn {

                }
                .save-btn {
                    margin: 0px 0px 0px 10px;
                }
            }
        }

        .date-last-update {
            margin: 30px 0px 0px 0px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 12px;
            text-align: left;
            color: #000000;
        }
    }
}
</style>
